"use client";

import { useEffect, useRef, useState } from "react";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { useRouter, useSearchParams } from "next/navigation";
import Loader from "components/Loader";

import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useSessionState from "hooks/useSessionState";
import useErrorLogger from "hooks/useErrorLogger";
import { validateAuthToken } from "lib/authApi";
import { ERROR_TYPES } from "utils/constants";

const StaffLogin = ({ redirectTo }: { redirectTo?: string; showLogs?: boolean }) => {
  const [isMounted, setIsMounted] = useState(false);
  const router = useRouter();
  const searchParams = useSearchParams();
  const initTokenValidated = useRef(false);
  const { session, isSessionLoading, isUserInValidOrg, peopleDataFetched, user } = useSessionState();
  const supabase = useSupabaseBrowser();
  const { logError } = useErrorLogger();
  const origin = typeof window !== "undefined" && window.location.origin ? window.location.origin : "";
  const pageUrl = typeof window !== "undefined" ? window.location.href : "";

  const supabaseAddedParams = pageUrl ? new URLSearchParams(new URL(pageUrl).hash.slice(1)) : undefined;
  const accessToken = searchParams?.get("access_token") || supabaseAddedParams?.get("access_token");

  const user_token = searchParams?.get("user_token");
  const showLoader =
    searchParams?.get("source") === "quiz" ||
    user_token ||
    searchParams?.get("token") ||
    accessToken ||
    !pageUrl ||
    isSessionLoading ||
    !!session;

  useEffect(() => {
    setIsMounted(true);
  }, []);

  useEffect(() => {
    if (initTokenValidated.current) return;

    if (user_token) {
      initTokenValidated.current = true;
      validateAuthToken(user_token)
        .then((result) => {
          if (!result.success) {
            logError({
              error: {
                name: "Buildappeal App Error",
                message: result.error || "Failed to validate token"
              },
              message: `Failed to  validate token: ${user_token}`,
              source: "StaffLogin - validateToken",
              type: ERROR_TYPES.USER_AUTH,
              url: window.location.href,
              additionalInfo: {
                user_token
              }
            });
            router.push("/login");
            return;
          }
          const url = result.data?.loginUrl;
          if (url) {
            window.location.href = url;
          } else {
            router.push("/login");
          }
        })
        .catch((err) => {
          logError({
            error: err,
            message: `Failed to validate token: ${user_token}`,
            source: "StaffLogin - validateToken",
            type: ERROR_TYPES.USER_AUTH,
            url: window.location.href,
            additionalInfo: {
              user_token
            }
          });
          router.push("/login");
        });
    }
  }, [searchParams, router, user_token, logError]);

  if (showLoader && !(user?.id && peopleDataFetched && !isUserInValidOrg)) {
    return (
      <div className="flex h-screen w-screen items-center justify-center">
        <Loader className="text-base-disabled" />
      </div>
    );
  }
  if (user?.id && peopleDataFetched && !isUserInValidOrg) {
    return (
      <div className="flex h-screen w-screen items-center justify-center text-center text-sm text-red-700">
        {" "}
        You are not authorized to access this organization! Please enter your organization URL and try again.
      </div>
    );
  }

  if (!isMounted) return null;

  return (
    <Auth
      supabaseClient={supabase}
      appearance={{
        theme: ThemeSupa
      }}
      providers={["google"]}
      redirectTo={redirectTo ? redirectTo : `${origin}/table`}
      onlyThirdPartyProviders
    />
  );
};

export default StaffLogin;
