"use client";
import { useRouter } from "next/navigation";
import { useCallback, useEffect } from "react";

import { NOTIFICATION_TYPE, NotificationRecord } from "components/Notifications/utils";
import SliderLoading from "components/SliderLoading";
import { quizSliderSteps } from "components/SliderLoading/utils";

import useSessionState from "hooks/useSessionState";
import useRealtimeNotificationByType from "hooks/useRealtimeNotificationByType";

const ClientQuizLoader = () => {
  const { currentUser, updateNewUserPhone } = useSessionState();
  const router = useRouter();

  const onQuizSubmissionCompleted = useCallback(
    (notification: NotificationRecord) => {
      const redirectUrl = notification.redirectUrl || "/";
      router.push(redirectUrl);
    },
    [router]
  );

  useEffect(() => {
    if (!currentUser?.id) {
      return;
    }
    updateNewUserPhone();
  }, [currentUser, updateNewUserPhone]);

  useRealtimeNotificationByType({
    type: NOTIFICATION_TYPE.QUIZ_SUBMISSION_COMPLETED,
    callback: onQuizSubmissionCompleted
  });
  return <SliderLoading sliderSteps={quizSliderSteps(currentUser?.first_name || "")} />;
};

export default ClientQuizLoader;
