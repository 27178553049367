import Image from "next/image";
import { Suspense } from "react";
import Slider from "./SlickSlider";

import "./style.css";

const sliderSettings = {
  dots: false,
  arrows: false,
  centerMode: true,
  infinite: true,
  variableWidth: true,
  speed: 1000,
  autoplay: true
};

type SliderLoadingProps = {
  sliderSteps: {
    id: number;
    title: string;
    subtitle: string;
    icon: string;
  }[];
  message?: string;
};

const SliderLoading = ({ sliderSteps, message = "We're building your project..." }: SliderLoadingProps) => {
  return (
    <div className="fixed inset-0 top-20 flex h-screen w-full items-center justify-center">
      <div className="flex flex-col items-center justify-center pb-20 font-heading text-primary-700">
        <div>
          <Suspense>
            {/* @ts-ignore */}
            <Slider {...sliderSettings} className="slider-loading max-w-[320px] md:max-w-sm">
              {sliderSteps?.map((step) => (
                <div className="flex items-center justify-center text-center" key={step.id}>
                  <span className="font-matter text-xs font-medium uppercase text-primary-700">{step.subtitle}</span>
                  <p className="text-accent-700 mb-6 mt-4 max-w-sm px-6 text-3xl">{step.title}</p>
                  <Image alt={step.title} src={step.icon} height={100} width={100} className="m-auto" />
                </div>
              ))}
            </Slider>
          </Suspense>
        </div>
        {message && <p>{message}</p>}
        <Suspense>
          <div
            className="mt-8 inline-block h-8 w-8 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status"
          >
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
              Loading...
            </span>
          </div>
        </Suspense>
      </div>
    </div>
  );
};

export default SliderLoading;
