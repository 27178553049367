import clsx from "clsx";
import { InputOTP, InputOTPGroup, InputOTPSlot } from "components/ui/input-otp";

type OtpCodeInputProps = Omit<React.ComponentPropsWithoutRef<"input">, "value" | "onChange" | "placeholder"> & {
  error?: {
    message: string;
  };
  inputRef?: React.Ref<HTMLInputElement>;
  value: string;
  onChange: (value: string) => void;
  description?: string;
  className?: string;
};

const OtpCodeInput = ({
  inputRef,
  value,
  description = "Enter your one-time password",
  className,
  ...rest
}: OtpCodeInputProps) => {
  return (
    <div className={clsx("flex flex-col items-center gap-2", className)}>
      <InputOTP maxLength={6} value={value} ref={inputRef} autoFocus {...rest}>
        <InputOTPGroup>
          <InputOTPSlot index={0} />
          <InputOTPSlot index={1} />
          <InputOTPSlot index={2} />
          <InputOTPSlot index={3} />
          <InputOTPSlot index={4} />
          <InputOTPSlot index={5} />
        </InputOTPGroup>
      </InputOTP>
      <div className="text-xs text-gray-500">{description}</div>
    </div>
  );
};

export default OtpCodeInput;
