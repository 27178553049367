export const quizSliderSteps = (firstName: string) => [
  {
    id: 1,
    subtitle: "Welcome to the family",
    title: `Nice to meet you, ${firstName}. Let's make your dream come true.`,
    icon: "/dream_house.svg"
  },
  {
    id: 2,
    subtitle: "Free consultation",
    title: "Speak with an expert for free. No pressure and no obligation.",
    icon: "/relax.svg"
  },
  {
    id: 3,
    subtitle: "You're in good hands",
    title: "We've completed 2800+ projects for people like you.",
    icon: "/blueprints.svg"
  }
];
