import { useEffect } from "react";
import {
  NOTIFICATION_TYPE,
  NotificationRecord,
  transformPayloadToNotificationRecord
} from "components/Notifications/utils";
import { Notification } from "types/apiTypes";
import useSupabaseBrowser from "utils/supabaseBrowserClient";
import useCurrentUser from "./useCurrentUser";
const isLocalDev = !process.env.NEXT_PUBLIC_VERCEL_URL;

// listens to specific type of notifications
const useRealtimeNotificationByType = ({
  type,
  callback
}: {
  type: NOTIFICATION_TYPE;
  callback: (payload: NotificationRecord) => void;
}) => {
  const supabaseClient = useSupabaseBrowser();
  const currentUser = useCurrentUser();

  useEffect(() => {
    if (!currentUser?.user_id || isLocalDev) return;
    const channel = supabaseClient
      .channel("table-db-changes")
      .on(
        "postgres_changes",
        {
          event: "INSERT",
          schema: "public",
          table: "user_notifications",
          filter: `type=eq.${type}`
        },
        (payload) => {
          if (payload?.new) {
            const notificationRecord = transformPayloadToNotificationRecord(payload.new as Notification);
            // trigger for notification user only
            if (currentUser?.user_id === notificationRecord.userId) {
              callback(notificationRecord);
            }
          }
        }
      )
      .subscribe();
    return () => {
      channel.unsubscribe();
    };
  }, [type, callback, supabaseClient, currentUser?.user_id]);
  return null;
};

export default useRealtimeNotificationByType;
